import { instance } from '@/utils/http.js'


// 获取全部科目
export const getAllSubject = function () {
  return instance({
    url: '/api/v1/papers/subject',
    method: 'get'
  })
}

// 获取教师科目
export const getUserSubject = function () {
  return instance({
    url: '/api/v1/public/teacher_subject_lst',
    method: 'get'
  })
}

