<template>
  <div>
    <el-form :model="form"
             :rules="rules"
             ref="formRef"
             label-width="80px">
      <el-row :gutter="10">
        <el-col :span="23">
          <el-form-item label="试卷名称">
            <el-input clearable
                      placeholder="请输入"
                      style="width:100%"
                      v-model="form.paper_name" />
          </el-form-item>
        </el-col>
        <el-col :span="23">
          <el-form-item label="试卷科目"
                        prop="subjectId">
            <el-select v-model="form.subjectId"
                       style="width:100%"
                       @change="subjectChage"
                       placeholder="选择">
              <el-option v-for="item in subjectList"
                         :key="item.subject_id"
                         :label="item.subject_name"
                         :value="item.subject_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="23">
          <el-form-item label="试卷地区"
                        prop="paper_district_id">
            <el-select v-model="form.paper_district_id"
                       style="width:100%"
                       filterable
                       :no-data-text="this.form.subjectId?'无数据':'先选择科目'"
                       placeholder="选择">
              <el-option v-for="item in districtList"
                         :key="item.paper_district_id"
                         :label="item.paper_district_name"
                         :value="item.paper_district_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

      </el-row>

      <el-row :gutter="10"
              style="text-align:center;margin-top:20px">
        <el-col :span="24">
          <el-button type="primary"
                     @click="toInfo">提交</el-button>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { getUserSubject } from '@/api/subject.js'
export default {
  data () {
    return {
      form: {},
      subjectList: [],
      districtList: [],
      rules: {
        subjectId: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        paper_district_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
      },
    }
  },
  mounted () {
  },
  methods: {
    toInfo () {
      this.$refs.formRef.validate(val => {
        if (!val) return
        let form = JSON.parse(JSON.stringify(this.form))
        form.subject_id = form.subjectId
        delete form.subjectId

        this.$http({
          url: '/api/v1/combination/rule_create',
          method: 'post',
          data: form
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '操作成功!',
            type: 'success'
          });
          this.$router.push('/testPapercomposition/onlyPaper/info?edu_paper_id=' + res.data.edu_paper_id + '&module=6')

        })
      })
    },
    getDirList () {
      this.$http({
        url: '/api/v1/combination/rule_subject_district',
        method: 'get',
        params: {
          subject_id: this.form.subjectId
        }
      }).then(res => {
        this.districtList = Object.values(res.data)
        if (this.form.paper_district_id) {
          let len = this.districtList.filter(item => item.paper_district_id == this.form.paper_district_id)
          if (len && len.length == 0) {
            this.$set(this.form, 'paper_district_id', '')
          }
        } else {
          this.$set(this.form, 'paper_district_id', this.districtList[0].paper_district_id)
        }
      })
    },

    subjectChage () {
      this.getDirList()
    },


    getList () {
      this.getSubjectList()
      // this.getDirList()
    },
    async getSubjectList () {
      const { data } = await getUserSubject()
      this.subjectList = data.list
      this.$set(this.form, 'subjectId', this.subjectList[0].subject_id)
      this.subjectChage()
    },
  }
}
</script>

<style lang="scss" scoped>
</style>